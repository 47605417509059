import React, { Component } from 'react'
import { Modal, ModalBody } from 'reactstrap';
import Project from './Project';
import Ressource from './Ressource';
import ToolButton from './ToolButton';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import API from '../Api';
import axios from 'axios';

function withHooks(Component) {
  return props => <Component {...props} navigate={useNavigate()} params={useParams()} location={useLocation()}/>;
}

const LOGOUT_TIMEOUT = 30
const LOGOUT_WARN_TIMEOUT = 20
let runner

class SelectRessource extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      username: null,
      tariff: 'price_non_member',
      categoryPath: [],
      projects: [],
      ressources: JSON.parse(localStorage.getItem('ressources')),
      sending: false,
      project: null,
      remainingTime: LOGOUT_TIMEOUT,
      logoutTimeoutWarning: false,
    }

    this.displayError = this.displayError.bind(this);
    this.toggle = this.toggle.bind(this);
    this.upOneCategory = this.upOneCategory.bind(this);
    this.categoryClicked = this.categoryClicked.bind(this)
    this.enterSubcategory = this.enterSubcategory.bind(this)
    this.resourceClicked = this.resourceClicked.bind(this)
    this.enterResource = this.enterResource.bind(this)
    this.openCashDrawer = this.openCashDrawer.bind(this)
    this.selectProject = this.selectProject.bind(this)
    this.timePing = this.timePing.bind(this)
    this.resetLogoutTimeout = this.resetLogoutTimeout.bind(this)
    this.logout = this.logout.bind(this)
  }

  displayError(error) {
    this.props.navigate('/error', { state: {message: error} });
  }

  componentWillMount() { 
    this.loadUser();

    runner = setInterval(() => {
      this.timePing();
    }, 1000);
  }

  componentWillUnmount() {
    if (runner) {
      clearInterval(runner)
    }
  }

  /*
  Timer used for automatically logging off inactive users after a while
  */
  timePing() {
    let timeLeft = this.state.remainingTime

    if (timeLeft === 0) {
      this.logout()
    }
    else {
      //console.log("Time ping", timeLeft)

      let shouldWarn = (this.state.remainingTime < LOGOUT_TIMEOUT - LOGOUT_WARN_TIMEOUT)

      this.setState(prevState => ({
        remainingTime: prevState.remainingTime - 1,
        logoutTimeoutWarning: shouldWarn
      }))
    }
  }

  resetLogoutTimeout() {
    this.setState({remainingTime: LOGOUT_TIMEOUT, logoutTimeoutWarning: false})
  }

  loadUser() {
    const tag = this.props.params.tagId;

    API.get('/user2/' + tag).then(response => {
      if (response.status === 200) {
        let username = response.data.visa;
        let isAnimateur = response.data.animateur;
        let tariff = response.data.tariff;
        // console.log(username);

        this.setState({
          username, isAnimateur, tariff
        });

        this.loadProjectsForUser(username);

      } else {
        this.displayError('Server is not responding');
      }
    }).catch(error => {
      this.displayError(`TAG ID : ${tag}`)
    });
  }

  loadProjectsForUser(username) {
    API.get('/projects/' + username).then(response => {
      if (response.status === 200) {
        let projects = response.data;
        this.setState({
          projects
        });
      } else {
        // this.displayError('Server is not responding');
      }
    }).catch(error => {
      // this.displayError(error)
    });
  }

  selectProject(selected) {
    this.resetLogoutTimeout()
    this.setState({project: selected});
    this.toggle();
  }

  toggle() {
    this.resetLogoutTimeout()
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  logout() {
    this.props.navigate('/');
  }

  upOneCategory() {
    this.resetLogoutTimeout()

    let newCatPath = this.state.categoryPath.slice(0, -1)

    console.log(newCatPath)
    
    this.setState({
        categoryPath: newCatPath
      }
    );
  }

  categoryClicked(cat) {
    console.log("Category clicked", cat)
    this.resetLogoutTimeout()
    this.enterSubcategory(cat)
  }

  enterSubcategory(subcat) {
    this.setState(prevState => (
      {
        categoryPath: [...prevState.categoryPath, subcat]
      })
    )
  }

  resourceClicked(res, type, unit, username, projectInUrl) {
    this.resetLogoutTimeout()
    console.log("Resource clicked", res, type, unit, username, projectInUrl)
    this.enterResource(res, type, unit, username, projectInUrl)
  }

  enterResource(res, type, unit, username, projectInUrl) {
    let slug = `/usage/${res}/${type}/${unit}/${username}`
    this.props.navigate(slug, { state: {project: projectInUrl}})
  }

  openCashDrawer() {
    this.resetLogoutTimeout()
    console.log("Opening cash drawer")
    axios.get("http://localhost:8080/hello")
  }


  render() {
    const username = this.state.username;
    const isAnimateur = this.state.isAnimateur;
    const ressources = this.state.ressources;
    const categoryPath = this.state.categoryPath;
    const projects = this.state.projects;
    const project = this.state.project;
    const logoutTimeoutWarning = this.state.logoutTimeoutWarning
    const tariff = this.state.tariff

    let projectChooser="";
    let header;

    if(project === null) {
      if(projects && projects.length>0){
        projectChooser = <div>
        
          <ToolButton className="navbuttonfree" onClicked={this.toggle} label="Projet" icon={process.env.PUBLIC_URL + '/ui_icons/archive.svg'}></ToolButton>
          </div>
      }
    } else {
      projectChooser = 

      <ToolButton className="navbuttonfree" onClicked={this.toggle} label={project} icon={process.env.PUBLIC_URL + '/ui_icons/archive.svg'}></ToolButton>
    }

    let cashDrawer="";
    
    if(isAnimateur){ cashDrawer = 
        <ToolButton className="navbutton btn-cash" icon={process.env.PUBLIC_URL + '/ui_icons/dollar-sign-s1.svg'} onClicked={this.openCashDrawer}></ToolButton>
      }

    let navBarMainText
    if (categoryPath.length === 0) {
      navBarMainText = <p>Catégories</p>
    }
    else {

      let barText = ""

      // for (let i = 0; i < categoryPath.length; i++) {
      //   if (i > 0) {
      //     barText += " / "
      //   }
      // }
      barText += categoryPath[categoryPath.length - 1]

      navBarMainText = <p>{ barText }</p>
    }



    if(username === null) {
      header = <div className="topbar"><div className="navtitle"><p>Chargement...</p></div></div>
    } else {
      header = 
         
          <div className="topbar">

            <div className="navtitle">

              { categoryPath.length > 0 &&
                <div className="row-back">
                  <ToolButton className="navbutton btn-back" icon={process.env.PUBLIC_URL + '/ui_icons/arrow-left-s1.svg'} onClicked={this.upOneCategory}></ToolButton>
                </div>
              }
              { navBarMainText }
            </div>

            <div className="userbar">

              

              <span className="uppercase userabbr">
              <img src={process.env.PUBLIC_URL + '/ui_icons/user-fill-24-black.svg'} alt=""/>
                { username }
              </span>

              <div className="project-selected">
                { projectChooser }
              </div>
              { cashDrawer }
                <Link to="/">
                  <ToolButton className="navbutton btn-logout" icon={process.env.PUBLIC_URL + '/ui_icons/log-out-s1.svg'}></ToolButton>
                </Link>
            </div>




              
          </div>
    }

    let projectInUrl = project;

    // Get appropriate resources for current navigation level
    let categoryCurrentSubtree = ressources[0]

    for (let subcatItem of categoryPath) {
      let newSubtree = categoryCurrentSubtree.items.filter(item => item.type === "category" && item.name === subcatItem)[0]
      categoryCurrentSubtree = newSubtree
    }


    return (
      
      <div className="page_main">
        
        { header }

        { username != null && 
          <div className="categoriesList">

            { categoryCurrentSubtree.items.map(res =>
              
              {


                let itemBgImageUrl = null
                let imageIsCovering = false

                if ('image' in res) {
                  itemBgImageUrl = 'http://' + res.image
                }
                if ('layout' in res) {
                  if (res.layout === 'full') {
                    imageIsCovering = true
                  }
                }

                if (res.type === "category") {
                  return (
                    <div key={res.name} className="categoryItem">
                      <Ressource name={res.name} isCategory={true} bgImage={itemBgImageUrl} bgImageIsCovering={imageIsCovering} onClicked={this.categoryClicked.bind(null, res.name)} />
                    </div>
                  )
                }

                else { // Resource
                  const widget = res.widget.toLowerCase();
                  const unit = res.unit !== '' ? res.unit : '-'; // Set a default value for unit

                  
                  let itemPrice = res[tariff]
                  // Format the price
                  if (itemPrice.substring(0, 2) === "0.") {
                    itemPrice = "—." + itemPrice.substring(2)
                  }
                  if (itemPrice.substring(itemPrice.length - 3) === ".00") {
                    itemPrice = itemPrice.substring(0, itemPrice.length - 3) + ".—"
                  }


                  const priceDisplay = `${itemPrice} / ${unit}`


  
                  return (
                  <div key={res.slug + res.name} className="categoryItem">
                    <Ressource name={res.name} detail={priceDisplay} slug={res.slug} bgImage={itemBgImageUrl} bgImageIsCovering={imageIsCovering} onClicked={this.resourceClicked.bind(null, res.slug, widget, unit, username, projectInUrl)} />
                  </div>
                  )
                }
              }
            )}

            {
              logoutTimeoutWarning === true &&
              <div className="warn_overlay"><p>La session va se déconnecter automatiquement pour inactivité</p></div>
            }

          </div>
        }

        <Modal isOpen={this.state.modal} fade={false} toggle={this.toggle} className="projectsModal">
          <ModalBody className="projectsSelector">

            <div className="projectItem">
              <Project className="nonechoice" name="Aucun" icon={process.env.PUBLIC_URL + '/ui_icons/forbid-line.png'} onClicked={this.selectProject.bind(null, null)} />
            </div>
            
            { projects != null && projects.map(project =>
              <div className="projectItem" key={project}>
                <Project name={project} onClicked={this.selectProject.bind(null, project)} />
              </div>
            )}

            { projects != null && projects.length < 1 && 
                <span>No projects</span>
            }
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default withHooks(SelectRessource)
