import React, { Component } from 'react'
import classNames from 'classnames';

export default class ScreenButton extends Component {

  static defaultProps = {
    disabled: false,
    color: "basic",
    labelstyle: "basic",
    caption: null,
    icon: null,
  }

  state = {
    showPressed: false
  }

  handleClick() {
    if (this.props.disabled) {
      console.log("Is disabled")
      return
    }
    this.props.onClicked()
  }

  handleTouchStart() {
    console.log("Touch start")
    this.setState({showPressed: true})
  }
  handleTouchEnd() {
    console.log("Touch end")
    this.setState({showPressed: false})
  }
  handleTouchCancel() {
    console.log("Touch cancel")
    this.setState({showPressed: false})
  }

  handleClick = this.handleClick.bind(this)
  handleTouchStart = this.handleTouchStart.bind(this)
  handleTouchEnd = this.handleTouchEnd.bind(this)
  handleTouchCancel = this.handleTouchCancel.bind(this)

  render() {

    let { className, color, labelstyle, caption, icon } = this.props

    const classes = classNames(
      className,
      "screenButton",
      `sb-${color}`,
      `sb-lb-${labelstyle}`,
      {
        disabled: this.props.disabled,
        showPressed: this.state.showPressed
      }
    )

    return (

      <button className={classes} 
        onClick={this.handleClick} onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd} onTouchCancel={this.handleTouchCancel}>
        { icon != null && <img alt="" src={icon} />}
        { caption != null && <p className="label">{this.props.caption}</p>}
      </button>
    )
  }
}
