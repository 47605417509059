import React, { Component } from 'react'
import ScreenButton from '../Components/ScreenButton';

export default class Increment extends Component {

  constructor(props){
    super(props);

    this.state = {
      inputValue: 1
    }
  }

  componentWillMount() {
    this.props.updated(this.state.inputValue);
  }

  incrementQuantity() {
    this.setState((prevState) => ({
      inputValue: prevState.inputValue + 1
    }), () => this.props.updated(this.state.inputValue))
  }

  decrementQuantity() {
    if(this.state.inputValue > 1) {
      this.setState((prevState) => ({
        inputValue: prevState.inputValue - 1
      }), () => this.props.updated(this.state.inputValue))
    }
  }

  render() {
    return (
      <div className="entryBlock incrementEntryBlock">
        <ScreenButton className="keypad_num" onClicked={this.decrementQuantity.bind(this)} caption="−" labelstyle="keypadbig"></ScreenButton>
        <div className="valueDisplay">
          <span className="input-number">{this.state.inputValue}</span>
          <span className="inputUnit">{this.props.unit !== null ? ` ${this.props.unit}` : ''}</span>
        </div>
        <ScreenButton className="keypad_num" onClicked={this.incrementQuantity.bind(this)} caption="+" labelstyle="keypadbig"></ScreenButton>
      </div>
    )
  }
}
