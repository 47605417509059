import React, { Component } from 'react'
import API from '../Api';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ToolButton from './ToolButton';


function withNavigation(Component) {
  return props => <Component {...props} navigate={useNavigate()} />;
}

class Login extends Component {


  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      tag: '',
      tagTestMode: false,
      debugMode: false,
    }

    this.loadTestAccount = this.loadTestAccount.bind(this)
    this.loadError = this.loadError.bind(this)
    this.wsMessage = this.wsMessage.bind(this)
    this.toggleTestMode = this.toggleTestMode.bind(this)
    this.processReadTag = this.processReadTag.bind(this)
    this.displayError = this.displayError.bind(this)
  }
  
  loadRessources() {
    API.get('/items2').then(response => {
      if (response.status === 200) {
        this.setState({ ressources: response.data, loaded: true })
        localStorage.setItem('ressources', JSON.stringify(response.data))
      }
    }).catch(error => {
      //this.displayError(error)
    });
  }

  toggleTestMode() {
    this.setState(prevState => ({
      tag: "",
      tagTestMode: !prevState.tagTestMode
    }));
  }



  loadTestAccount = () => {
    let testId = "C163440F"
    this.processReadTag(testId)
  }  
  loadError = () => {
    this.props.navigate('/error', { state: {message: "Test err"} });
  }

  processReadTag(tag) {

    console.log("Process tag", tag)

    if (this.state.tagTestMode) {
        console.log("Testing tag now:", tag)
        this.setState({tag: tag})
        return
    }
    else {
      this.props.navigate('/user/' + tag);
    }
  }

  displayError(error) {
    this.props.navigate('/error', { state: {message: error} });
  }

  wsMessage(msg) {
    console.log("===== WSMESSAGE =====", msg.data)
    this.processReadTag(msg.data);
  }

  componentDidMount() {
    this.loadRessources();

    // Listen for new tag detected event
    var socket = new WebSocket('ws://127.0.0.1:5678');
    socket.onmessage = (e) => {
      console.log("Received message from ws:", e.data)
      this.wsMessage(e)
    }
  }

  render() {
    return (
      <div className="page_login">
        <div className="login_layout">
          <div className="logo">
            <img alt="" src={process.env.PUBLIC_URL + '/categories_media/fablabsion.png'} />
          </div>
          <div className="instructionsBox">
            { this.state.loaded && !this.state.tagTestMode &&
            <p className="instructions lead">
              <span>Approchez votre badge pour vous identifier</span>
              <br />
              <img alt="" src={process.env.PUBLIC_URL + '/ui_icons/arrow-down-line.svg'} />
            </p>}
            { this.state.loaded && this.state.tagTestMode &&
            <p className="instructions lead special">
              <span>Scannez un badge pour le tester</span>
              <br />
              <img alt="" src={process.env.PUBLIC_URL + '/ui_icons/arrow-down-line.svg'} />
            </p>}
            { !this.state.loaded &&
            <p className="instructions lead loading">
              Chargement...
            </p> }
          </div>
        </div>
        <div className="overlay">
          <div className="toolbar">
            { this.state.debugMode && 
              <ToolButton icon={process.env.PUBLIC_URL + '/ui_icons/dollar-sign-s1.svg'} label="Test account" onClicked={this.loadTestAccount}></ToolButton>
            }
            { this.state.tagTestMode && (
              <ToolButton className="modeEnabled" icon={process.env.PUBLIC_URL + '/ui_icons/radio-s1.svg'} label="Test cartes activé" onClicked={this.toggleTestMode}></ToolButton>
            ) }
            { !this.state.tagTestMode && (
              <ToolButton icon={process.env.PUBLIC_URL + '/ui_icons/radio-s1.svg'} onClicked={this.toggleTestMode}></ToolButton>
            ) }
          </div>

          {
            this.state.tag !== "" &&
            <div className='popup'>
              <div>{ this.state.tag }</div>
            </div>
          }

        </div>
      </div>
    )
  }
}

export default withNavigation(Login);
