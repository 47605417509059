import React, { Component } from 'react'
import ScreenButton from '../Components/ScreenButton';

const SEPARATOR = '.';
const MAX_LENGTH = 8;

export default class Keypad extends Component {

  constructor(props){
    super(props);

    this.state = {
      inputValue: ''
    }

    this.handleClick = this.handleClick.bind(this);
    this.delete = this.delete.bind(this);
  }

  delete() {
    this.setState((prevState) => ({
      inputValue: prevState.inputValue.slice(0, -1)
    }), () => this.props.updated(this.state.inputValue))
  }

  append(input) {
    const tempString = this.state.inputValue;

    if(tempString.length >= MAX_LENGTH)
      return;

    const alreadyHasSeparator = tempString.indexOf(SEPARATOR) > 0;

    if(input === SEPARATOR && alreadyHasSeparator)
      return;

    this.setState((prevState) => ({
      inputValue: prevState.inputValue + input
    }), () => this.props.updated(this.state.inputValue))
  }

  handleClick(input) {

    if(isNaN(parseInt(input))) {
      // Handle char

      let char = input;
      // console.log("Char typed " + char);

      this.append(char); 

    } else {
      // Handle num

      let num = parseInt(input);
      // console.log("Number typed " + num);

      this.append(num);      
    }
  }

  render() {
    return (
      <div className="entryBlock valueEntryBlock">
        <div className="keypad_num">
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(1)} caption="1">1</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(2)} caption="2">2</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(3)} caption="3">3</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(4)} caption="4">4</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(5)} caption="5">5</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(6)} caption="6">6</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(7)} caption="7">7</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(8)} caption="8">8</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(9)} caption="9">9</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(SEPARATOR)} caption={SEPARATOR}>{ SEPARATOR }</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(0)} caption="0">0</ScreenButton>
          <ScreenButton color="alt" onClicked={this.delete} icon={process.env.PUBLIC_URL + '/ui_icons/delete-back-2-line-white-48.svg'}>DEL</ScreenButton>
        </div>
        <div className="valueDisplay" >
          <div className="input-number">{this.state.inputValue}</div>
          <div className="inputUnit">{ this.props.unit }</div>
        </div>
      </div>
    )
  }
}
