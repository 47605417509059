import React, { Component } from 'react'
import ScreenButton from '../Components/ScreenButton';
import classNames from 'classnames';

const SEPARATOR = ':';
const MAX_LENGTH = 4;

export default class Duration extends Component {

  constructor(props){
    super(props);

    this.state = {
      inputValue: ''
    }

    this.handleClick = this.handleClick.bind(this);
    this.getDigit = this.getDigit.bind(this);
    this.isDigitBlank = this.isDigitBlank.bind(this);
    this.isDigitCurrent = this.isDigitCurrent.bind(this);
    this.delete = this.delete.bind(this);
  }

  delete() {
    this.setState((prevState) => ({
      inputValue: prevState.inputValue.slice(0, -1)
    }), () => this.props.updated(this.state.inputValue))
  }

  toSeconds(hoursAndMinutes) {
    const hours = hoursAndMinutes.slice(0, 2);
    const minutes = hoursAndMinutes.slice(2, 4);

    // console.log(hours, minutes);

    return hours * 3600 + minutes * 60;
  }

  append(input) {
    const tempString = this.state.inputValue;

    if(tempString.length >= MAX_LENGTH)
      return;

    this.setState((prevState) => ({
      inputValue: prevState.inputValue + input
    }), () => {

      let currentValue = this.state.inputValue;

      this.props.updated(this.toSeconds(currentValue));

    })
  }

  format(inputValue) {
    let hours = inputValue.slice(0, 2);
    let minutes = inputValue.slice(2, 4);

    let str = hours;

    // If length is sufficient to display ':'
    if(inputValue.length >= 2)
      str += SEPARATOR + minutes;

    return str;
  }

  getDigit(pos) {
    let inputValue = this.state.inputValue
    if (pos >= inputValue.length) {
      return "0"
    }  
    return inputValue.charAt(pos)
  }

  isDigitBlank(pos) {
    let inputValue = this.state.inputValue
    return (pos >= inputValue.length)
  }
  isDigitCurrent(pos) {
    let inputValue = this.state.inputValue
    return (pos === inputValue.length)
  }

  handleClick(input) {

    if(isNaN(parseInt(input))) {
      // Handle char

      let char = input;
      // console.log("Char typed " + char);

      this.append(char); 

    } else {
      // Handle num

      let num = parseInt(input);
      // console.log("Number typed " + num);

      this.append(num);      
    }
  }

  render() {
    
    return (
      <div className="entryBlock durationEntryBlock">
        <div className="keypad_num">
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(1)} caption="1">1</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(2)} caption="2">2</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(3)} caption="3">3</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(4)} caption="4">4</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(5)} caption="5">5</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(6)} caption="6">6</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(7)} caption="7">7</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(8)} caption="8">8</ScreenButton>
          <ScreenButton labelstyle="keypadbig" onClicked={() => this.handleClick(9)} caption="9">9</ScreenButton>
          <ScreenButton className="spanh2" labelstyle="keypadbig" onClicked={() => this.handleClick(0)} caption="0">0</ScreenButton>
          <ScreenButton color="alt" onClicked={this.delete} icon={process.env.PUBLIC_URL + '/ui_icons/delete-back-2-line-white-48.svg'}>DEL</ScreenButton>
        </div>
        <div className="durationDisplay" >
          <div className="inputDigitsBlock">
            <span className={classNames("inputDigit", {"current" : this.isDigitCurrent(0), "empty" : this.isDigitBlank(0)})}>{this.getDigit(0)}</span>
            <span className={classNames("inputDigit", {"current" : this.isDigitCurrent(1), "empty" : this.isDigitBlank(1)})}>{this.getDigit(1)}</span>
            <span className="inputSeparatorUnit">h</span>
            <span className={classNames("inputDigit", {"current" : this.isDigitCurrent(2), "empty" : this.isDigitBlank(2)})}>{this.getDigit(2)}</span>
            <span className={classNames("inputDigit", {"current" : this.isDigitCurrent(3), "empty" : this.isDigitBlank(3)})}>{this.getDigit(3)}</span>
            <span className="inputSeparatorUnit">min</span>
          </div>
        </div>
      </div>
    )
  }
}
