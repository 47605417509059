import React, { Component } from 'react'
import classNames from 'classnames';

export default class Ressource extends Component {

  state = {
    showPressed: false
  }

  handleClick() {
    this.props.onClicked()
  }
  handleTouchStart() {
    this.setState({showPressed: true})
  }
  handleTouchEnd() {
    this.setState({showPressed: false})
  }
  handleTouchCancel() {
    this.setState({showPressed: false})
  }

  handleClick = this.handleClick.bind(this)
  handleTouchStart = this.handleTouchStart.bind(this)
  handleTouchEnd = this.handleTouchEnd.bind(this)
  handleTouchCancel = this.handleTouchCancel.bind(this)

  render() {
    const classes = classNames(
      "categoryButton",
      {
        showPressed: this.state.showPressed
      }
    )

    let imageIsCovering = this.props.bgImageIsCovering

    return (

      <button className={classes}
        onClick={this.handleClick} onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd} onTouchCancel={this.handleTouchCancel}>

        {
          !this.props.bgImageIsCovering &&
            <div className="bgImage"/>
        }
        
        {
          this.props.bgImage &&
          <div className="bgImage" style={{
            backgroundImage: "url(" + this.props.bgImage  + ')',
            backgroundSize: (imageIsCovering ? 'cover' : 'unset'),
            backgroundRepeat: 'no-repeat', 
            backgroundPosition: 'center'}
          }  />
        }
        
        <div className="buttonContent">
          <p className="title">{this.props.name}</p>
          <p className="detail">{this.props.detail}</p>
        </div>
        
      </button>
    )
  }
}
