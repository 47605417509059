import React, { Component } from 'react'
import PulseLoader from 'react-spinners/PulseLoader';
import API from '../Api';
import Increment from '../Keyboards/Increment';
import Duration from '../Keyboards/Duration';
import Keypad from '../Keyboards/Keypad';
import { FaCheckCircle } from 'react-icons/fa';
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import ScreenButton from './ScreenButton';


function withHooks(Component) {
  return props => <Component {...props} navigate={useNavigate()} params={useParams()}  location={useLocation()}/>;
}

class UseRessource extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      quantity: null,
      sending: false,
      success: false
    }

    this.goBack = this.goBack.bind(this)
  }

  displayError(error) {
    console.log(error);

    this.setState({
      sending: false
    });

    this.props.history.push('/error');
  }

  sendUsage() {

    const username = this.props.params.username;
    const machine = this.props.params.machine;

    // TODO Get project
    //const project = this.props.params.project;

    const project = this.props.location.state.project

    const time = this.state.quantity;

    this.setState({
      sending: true
    })

    let projectInUrl = project != null ? '/' + project : '';

    API.get('/usage/' + machine + '/' + username + '/' + time + projectInUrl).then(response => {
      if (response.status === 200) {
        // console.log(response);

        this.setState({
          sending: false,
          success: true
        });

        setTimeout(() => {
          this.props.navigate(-1);
        }, 1000);

      } else {
        this.displayError('Server is not responding');
      }
    }).catch(error => {
      this.displayError(error);
    })
  }

  goBack() {
    this.props.navigate(-1)
  }


  render() {
    const type = this.props.params.type;
    const unitStr = this.props.params.unit;

    const unit = unitStr !== '-' ? unitStr : null; // If unit string is '-' set the unit to null

    const promptSentences = {
      'pce' : "Saisir la quantité",
      'CHF' : "Saisir le montant souhaité",
      'cm' : "Saisir la longueur du matériau",
      'g' : "Saisir la quantité de filament consommé",
      'ml' : "Saisir la quantité de résine consommée",
      'heure' : "Saisir la durée du travail",
    }

    let promptSentence = "Saisir la quantité"
    if (unit in promptSentences) {
      promptSentence = promptSentences[unit]
    }

    if(this.state.success && !this.state.sending) {
      return (
        <div className="page_progress">
          <FaCheckCircle size={200} color={'#57df2d'} />
        </div>
      )
    } else if(this.state.sending) {
      return (
        <div className="page_progress">
          <PulseLoader color="#aaa" size={60}/>
          <p>Envoi des données</p>
        </div>
      )
    } else {
      let keyboardUsed;

      switch(type) {
        case 'keypad':
          keyboardUsed = <Keypad unit={unit} updated={(newValue) => this.setState({quantity: newValue}) } />
          break;
        case 'duration':
          keyboardUsed = <Duration updated={(newValue) => this.setState({quantity: newValue}) } />
          break;
        default:
          keyboardUsed = <Increment unit={unit} updated={(newValue) => this.setState({quantity: newValue}) } />
        break;
      }

      return (
        <div className="page_entry">
          <div className="topbar">
            <p className="title">{ promptSentence }</p>
          </div>
          <div className="keypadZone">
            { keyboardUsed }
          </div>

          <div className="buttonsBar">
            <ScreenButton color="danger" onClicked={ this.goBack } caption="Annuler"></ScreenButton>
            <ScreenButton color="validate" disabled={ this.state.quantity !== null && this.state.quantity !== '' ? false : true } onClicked={() => this.sendUsage() } caption="Valider"></ScreenButton>
          </div>
        </div>
      )
    }
  }
}

export default withHooks(UseRessource)
