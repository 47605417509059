import React, { Component } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import ScreenButton from './ScreenButton';
import { FaExclamationCircle } from 'react-icons/fa';

function withHooks(Component) {
  return props => <Component {...props} navigate={useNavigate()} params={useParams()} location={useLocation()}/>;
}

class Error extends Component {

  constructor(props) {
    super(props)

    this.goHome = this.goHome.bind(this)
  }

  goHome() {
    this.props.navigate('/')
  }

  render() {
    const message = this.props.location.state != null ? this.props.location.state.message : 'Une erreur est survenue. Veuillez recommencer l\'opération.';

    return (
      <div className="page_error">
          <div className="msgbox">
            <FaExclamationCircle size={200} color={'#df7c7c'} />
            <p>{ message }</p>
          </div>

          <div className="buttonsrow">
            <ScreenButton caption="OK" onClicked={this.goHome}></ScreenButton>
          </div>

      </div>
    )
  }
}

export default withHooks(Error)
