import React, { Component } from 'react';
import './App.css';
import SelectRessource from './Components/SelectRessource';
import UseRessource from './Components/UseRessource';
import Login from './Components/Login';
import Error from './Components/Error';
import {
  BrowserRouter as Router,
  Route, Routes
} from 'react-router-dom';

class App extends Component {
  
  render() {
    return (
      <Router>
        <div className="topContainer">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/user/:tagId" element={<SelectRessource />} />
            {/* <Route path="/usage/:machine/:type/:unit/:username/:project?" element={<UseRessource />} /> */}
            <Route path="/usage/:machine/:type/:unit/:username" element={<UseRessource />} />
            <Route path="/error" element={<Error />} />
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
