import React, { Component } from 'react'
import classNames from 'classnames';

export default class ToolButton extends Component {

  state = {
    showPressed: false
  }

  handleClick() {
    if (!this.props.onClicked) {
      return
    }
    this.props.onClicked()
  }
  handleTouchStart() {
    this.setState({showPressed: true})
  }
  handleTouchEnd() {
    this.setState({showPressed: false})
  }
  handleTouchCancel() {
    this.setState({showPressed: false})
  }

  handleClick = this.handleClick.bind(this)
  handleTouchStart = this.handleTouchStart.bind(this)
  handleTouchEnd = this.handleTouchEnd.bind(this)
  handleTouchCancel = this.handleTouchCancel.bind(this)

  render() {

    let { className, icon } = this.props

    const classes = classNames(
      "toolButton",
      className,
      {
        showPressed: this.state.showPressed
      }
    )

    return (

      <button className={classes}
        onClick={this.handleClick} onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd} onTouchCancel={this.handleTouchCancel}>
        <div className="label">
          <p>{this.props.label}</p>
          { icon != null && <img alt="" src={icon} />}
        </div>
        
      </button>
    )
  }
}
